// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import * as ko from 'knockout';

ko.bindingHandlers.numericTextInput = {
  init: function (element, valueAccessor, allBindings) {
    const bindingOptions = ko.unwrap(valueAccessor());
    const numericValue = ko.observable(bindingOptions.value()).extend({
      numeric: {
        precision: bindingOptions.precision,
        allowEmpty: typeof bindingOptions.allowEmpty !== 'undefined' && bindingOptions.allowEmpty
      }
    });
    numericValue.subscribe(newValue => {
      bindingOptions.value(newValue);
    });
    ko.bindingHandlers.textInput.init(
      element,
      () => {
        return numericValue;
      },
      allBindings
    );
  }
};
